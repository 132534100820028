import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-388e0371"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "selectInput",
  ref: "selectInput",
  class: "select-input _r_select_my_use"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_select, {
      ref: "select",
      value: _ctx.propsValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.propsValue) = $event)),
      placeholder: _ctx.placeholder,
      onChange: _ctx.change,
      allowClear: "",
      style: _normalizeStyle({ '--content': _ctx.content })
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionList, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.value,
            value: item.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "placeholder", "onChange", "style"])
  ], 512))
}