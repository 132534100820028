
import { defineComponent, PropType, computed } from 'vue'
import Data from '@/model/common/Data'
interface Aegion {
  value: string
  label: string
}
export default defineComponent({
  name: 'SelectInput',
  props: {
    optionList: {
      type: Array as PropType<Aegion[]>,
      required: true,
    },
    //字符串需要用双引号包裹
    content: {
      type: String,
      required: true,
    },
    //
    inputValue: {
      type: null,
      required: true,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  emits: ['update:inputValue'],
  setup(props: Data, { emit }): Data {
    const propsValue = computed(() => {
      return props.inputValue
    })
    // const inputValue = ref('')
    const change = (val: string) => {
      emit('update:inputValue', val)
    }
    return {
      // inputValue,
      change,
      propsValue,
    }
  },
})
