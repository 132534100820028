import { $axios } from '../config/axios'
import { AxiosRequestConfig } from 'axios'
// import {
//   Demand,
//   RegDemand,
//   ResPeerClass,
//   AddDemand,
// } from '@/model/industryManagement/demandInformation'
import {
  Demand,
  RegDemand,
  ResDemandClass,
  ResDemandDetail,
} from '@/model/industryManagement/demand'
import Data from '@/model/common/Data'
import {
  Achievement,
  RegAchievement,
  ResAchievementClass,
} from '@/model/industryManagement/achievement'
class DemandApi {
  /*
  新版需求信息模块
  */
  //新版需求信息分页查询
  GetProposalList(data: RegDemand): Promise<ResDemandClass> {
    return $axios.post('/api/proposal/list', data)
  }
  //关注需求信息
  FollowData(id: string): Promise<Data> {
    return $axios.put(`/api/proposal/follow/add?id=${id}`)
  }
  //获取需求明细
  ProposalDetail(id: string): Promise<ResDemandDetail> {
    return $axios.get(`/api/proposal/detail?id=${id}`)
  }
  // 将需求转为终止状态
  ProposalTerminate(data: unknown): Promise<Data> {
    return $axios.post('/api/proposal/terminate', data)
  }
  //客户查看历史提交数据详情
  ProposalDetailHis(data: unknown): Promise<Demand> {
    return $axios.post('/api/proposal/customer/detail', data)
  }
  //客户获取提交历史记录
  ProposalCustomerHistory(): Promise<Demand[]> {
    return $axios.post('/api/proposal/customer/history', {})
  }
  //内部新增需求信息
  ProposalAdd(data: unknown): Promise<Demand> {
    return $axios.post('/api/proposal/add', data)
  }
  //批量删除需求信息
  ProposalDelete(data: unknown): Promise<Data> {
    return $axios.post('/api/proposal/delete', data)
  }
  //取消关注需求信息
  FollowCancel(id: string): Promise<Data> {
    return $axios.delete(`/api/proposal/follow/cancel?id=${id}`)
  }
  //外部新增需求信息
  ProposalCustomerAdd(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/customerAdd', data)
  }
  //修改需求信息
  ProposalUpdate(data: Demand): Promise<Data> {
    return $axios.post('/api/proposal/update', data)
  }
  //需求转提案
  ProposalToDemand(data: unknown): Promise<Data> {
    return $axios.post('/api/proposal/toDemand', data)
  }
  //需求文件上传接口
  uploadFile(data: FormData, config?: AxiosRequestConfig): Promise<string> {
    return $axios.post('/api/file/uploadFile', data, config)
  }
  //根据需求编号获取成果信息详情
  GetAchievementList(data: unknown): Promise<Achievement[]> {
    return $axios.post('/api/demand/getAchievementList', data)
  }
  //成果详情新增
  AddAchievementInfoVO(data: unknown): Promise<Achievement> {
    return $axios.post('/api/demand/achievementInfoVO/add', data)
  }
  //成果详情删除
  DelAchievementInfoVO(data: unknown): Promise<Achievement> {
    return $axios.post('/api/demand/achievementInfoVO/delete', data)
  }
  //发送终止信息
  SendMsg(data: unknown): Promise<unknown> {
    return $axios.post('/api/proposal/sendMsg', data)
  }
  //通过id下载
  DemanddownloadSec(id: string): Promise<ArrayBuffer> {
    return $axios.get('/api/file/preview', {
      params: { id },
      responseType: 'arraybuffer',
    })
  }
}
let demandApi
export default (() => {
  if (demandApi) return demandApi
  demandApi = new DemandApi()
  return demandApi
})()
