
import { computed, defineComponent } from 'vue'
import SelectInput from '@/components/selectInput/SelectInput.vue'
// import Data from '@/model/common/Data'
export default defineComponent({
  name: 'formItem',
  components: { SelectInput },
  props: ['value', 'formItemContent', 'id'],
  emits: ['update:value', 'change', 'blur', 'pressEnter'],
  setup(props, { emit }) {
    //返回props对象
    const formPropItem = computed(() => props.formItemContent)
    //返回选择下拉框label
    const content = computed(() => `"${props.formItemContent.label}"`)
    interface Data {
      [key: string]: Data
    }
    //返回props的值
    const propsValue = computed(() => {
      return props.value
    })
    //input发生改变时 改变父组件源表单数据
    const inputChange = (e: Data | number) => {
      if (typeof e == 'number') {
        emit('update:value', e)
      } else {
        emit('update:value', e?.target.value)
        emit('change', e?.target.value)
      }
    }
    const inputBlur = () => {
      emit('blur')
    }
    //选择框发生改变时
    const selectChange = (val: string) => {
      emit('update:value', val)
      emit('blur', val)
      emit('change', val)
    }
    //开关发生改变
    const switchChange = (checked: unknown) => {
      emit('update:value', checked)
      emit('blur', checked)
      emit('change', checked)
    }
    //日期选择器改变事件
    const dateChange = (date: string) => {
      console.log(date)
      emit('update:value', date)
      emit('change', date)
    }
    //按下回车事件
    const pressEnter = () => {
      emit('pressEnter')
    }
    return {
      formPropItem,
      inputChange,
      selectChange,
      content,
      propsValue,
      switchChange,
      dateChange,
      pressEnter,
      inputBlur,
    }
  },
})
