import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_ctx.formItemContent.type === 'SelectInput')
    ? (_openBlock(), _createBlock(_component_SelectInput, {
        key: 0,
        "onUpdate:inputValue": _ctx.selectChange,
        inputValue: _ctx.propsValue,
        content: _ctx.content,
        optionList: _ctx.formItemContent.optionList
      }, null, 8, ["onUpdate:inputValue", "inputValue", "content", "optionList"]))
    : (_ctx.formItemContent.type === 'SelectDate')
      ? (_openBlock(), _createBlock(_component_a_date_picker, {
          key: 1,
          value: _ctx.propsValue,
          valueFormat: _ctx.formItemContent.valueFormat || 'YYYY-MM-DD',
          onChange: _ctx.dateChange,
          size: "large",
          style: _normalizeStyle({ '--content': _ctx.content }),
          class: "SelectDate"
        }, null, 8, ["value", "valueFormat", "onChange", "style"]))
      : (_ctx.formItemContent.type === 'input')
        ? (_openBlock(), _createBlock(_component_a_input, {
            key: 2,
            value: _ctx.propsValue,
            placeholder: _ctx.formItemContent.placeholder,
            onChange: _ctx.inputChange,
            onPressEnter: _ctx.pressEnter
          }, {
            prefix: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formItemContent.label), 1)
            ]),
            _: 1
          }, 8, ["value", "placeholder", "onChange", "onPressEnter"]))
        : (_ctx.formItemContent.type === 'switch')
          ? (_openBlock(), _createBlock(_component_a_switch, {
              key: 3,
              checked: _ctx.propsValue,
              "checked-children": "开",
              checkedValue: _ctx.formItemContent.checkedValue || 1,
              unCheckedValue: 
      _ctx.formItemContent.unCheckedValue === false
        ? _ctx.formItemContent.unCheckedValue
        : _ctx.formItemContent.unCheckedValue || 0
    ,
              onChange: _ctx.switchChange,
              disabled: _ctx.formItemContent.disabled
            }, null, 8, ["checked", "checkedValue", "unCheckedValue", "onChange", "disabled"]))
          : (_ctx.formItemContent.type === 'subInput')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 4,
                value: _ctx.propsValue,
                placeholder: _ctx.formItemContent.placeholder,
                onChange: _ctx.inputChange,
                onBlur: _ctx.inputBlur,
                type: _ctx.formItemContent.inputType,
                readonly: _ctx.formItemContent.readonly,
                size: _ctx.formItemContent.size || 'default',
                "allow-clear": "",
                maxlength: _ctx.formItemContent.maxlength,
                disabled: _ctx.formItemContent.disabled
              }, null, 8, ["value", "placeholder", "onChange", "onBlur", "type", "readonly", "size", "maxlength", "disabled"]))
            : (_ctx.formItemContent.type === 'numberInput')
              ? (_openBlock(), _createBlock(_component_a_input_number, {
                  key: 5,
                  value: _ctx.propsValue,
                  placeholder: _ctx.formItemContent.placeholder,
                  onChange: _ctx.inputChange,
                  readonly: _ctx.formItemContent.readonly,
                  size: _ctx.formItemContent.size || 'default',
                  disabled: _ctx.formItemContent.disabled
                }, null, 8, ["value", "placeholder", "onChange", "readonly", "size", "disabled"]))
              : (_ctx.formItemContent.type === 'textarea')
                ? (_openBlock(), _createBlock(_component_a_textarea, {
                    key: 6,
                    value: _ctx.propsValue,
                    placeholder: _ctx.formItemContent.placeholder,
                    readonly: _ctx.formItemContent.readonly,
                    onChange: _ctx.inputChange,
                    autoSize: _ctx.formItemContent.autoSize ? _ctx.formItemContent.autoSize : { minRows: 3, maxRows: 6 },
                    maxlength: _ctx.formItemContent.maxlength ? _ctx.formItemContent.maxlength : null,
                    disabled: _ctx.formItemContent.disabled
                  }, null, 8, ["value", "placeholder", "readonly", "onChange", "autoSize", "maxlength", "disabled"]))
                : (_ctx.formItemContent.type === 'subSelect')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 7,
                      disabled: _ctx.formItemContent.disabled,
                      value: _ctx.propsValue,
                      placeholder: _ctx.formItemContent.placeholder,
                      onChange: _ctx.selectChange,
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemContent.optionList, (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item.value,
                            value: item.value,
                            disabled: item.disabled
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label || item.value), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "disabled"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["disabled", "value", "placeholder", "onChange"]))
                  : (_ctx.formItemContent.type === 'subDate')
                    ? (_openBlock(), _createBlock(_component_a_date_picker, {
                        key: 8,
                        value: _ctx.propsValue,
                        valueFormat: _ctx.formItemContent.valueFormat ? _ctx.formItemContent.valueFormat : 'YYYY-MM-DD',
                        onChange: _ctx.dateChange,
                        "show-time": _ctx.formItemContent.showTime ? _ctx.formItemContent.showTime : false,
                        size: _ctx.formItemContent.size || 'default'
                      }, null, 8, ["value", "valueFormat", "onChange", "show-time", "size"]))
                    : (_ctx.formItemContent.type === 'slot')
                      ? _renderSlot(_ctx.$slots, _ctx.formItemContent.prop, { key: 9 }, undefined, true)
                      : _createCommentVNode("", true)
}